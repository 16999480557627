.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.portfolio__item:hover h3 {
  border-color: var(--color-primary-variant);
  color: var(--color-primary-variant);
  background: transparent;
}

.portfolio__item:hover .btn {
  border-color: var(--color-primary-variant);
  color: var(--color-primary-variant);
  background: transparent;
}

.portfolio__item-image {
  width: 100%; /* Make it span the full width of the container */
  height: 200px; /* Set a fixed height for uniformity */
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item-image img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.portfolio__item h3 {
  display: flex;
  justify-content: center;
  margin: 1.2rem 0 2rem;
  color: white;
}

.portfolio__item-cta {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
  color: var(--color-primary-variant);
}

.portfolio__item-cta > .btn {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  color: var(--color-white);
  background-color: black;
}

.portfolio__item-cta > .btn:hover {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  background-color: var(--color-bg-variant);
  color: var(--color-primary-variant);
}

/* ================== MEDIA (MEDIUM DEVICES) -- TABLETS) =================== */

@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

/* ================== SMALL (SMALL DEVICES) -- PHONES) =================== */

@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
